import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  initialize() {
    this.quantity = 1;
    // this.eventId = Number(this.element.dataset.event);
    this.variantId = Number(this.element.dataset.variant);
    this.vendorId = Number(this.element.dataset.vendor);
  }

  addToCart() {
    let addToCartButton = document.querySelector(`#add-to-cart-${this.variantId}`);
    let authenticity_token = document.querySelector(
      'meta[name="csrf-token"]'
    ).content;

    addToCartButton.disabled = true;

    let eventId = window.location.pathname.replace('?', '""').split("/").pop();

    fetch(Spree.pathFor("cart/add_item"), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        variant_id: this.variantId,
        event_ids: [eventId],
        vendor_id: this.vendorId,
        quantity: this.quantity,
        authenticity_token: authenticity_token,
        options: {},
      }),
    }).then((response) => {
      switch (response.status) {
        case 200:
          this.showCart();
          break;
      }

      addToCartButton.disabled = false;
    });
  }

  showCart() {
    let cartModal = document.querySelector(".cart-modal-container");
    cartModal.classList.remove("hidden");

    setTimeout(() => {
      cartModal.classList.add("hidden");
    }, 5250);
  }
}
