console.log("Vite ⚡️ Rails");

import "@hotwired/turbo-rails";
import "@hotwired/stimulus";

// Import all channels.
import.meta.globEager('./**/*_channel.js')

// Import all controllers.
import '../controllers';
import.meta.globEager('./**/*_controller.js')

// require.context("../images", true);
// import "../images";
