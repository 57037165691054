import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  initialize() {
    this.target = "cart_form_events_user_" + this.element.dataset.user;
    this.productSlug = this.element.dataset.product;
  }

  selectLocation(event) {
    let locationId = event.target.value;
    let variantId = this.getSelectedVariantId();

    this.getEvents(locationId, variantId);
  }

  selectVariantOption(event) {
    let locationId = this.getSelectedLocationId();
    let variantId = event.target.getAttribute("data-variant-id");

    this.getEvents(locationId, variantId);
  }

  // Could be private

  getSelectedVariantId() {
    let result = "";
    const variantInputElement = document.querySelector(
      "input[class='product-variants-variant-values-radio']:checked"
    );

    if (variantInputElement) {
      result = variantInputElement.getAttribute("data-variant-id");
    }

    return result;
  }

  getSelectedLocationId() {
    let result = "";
    const locationInputElement = document.getElementById('cart-location-selector');

    if (locationInputElement) {
      result = locationInputElement.value;
    }

    return result;
  }

  getEvents(locationId, variantId) {
    get(Spree.pathFor("products/events"), {
      query: {
        target: this.target,
        slug: this.productSlug,
        location_id: locationId,
        variant_id: variantId,
      },
      responseKind: "turbo-stream",
    });
  }
}
