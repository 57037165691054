import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  show() {
    let cartModal = document.querySelector(".cart-modal-container");
    cartModal.classList.remove("hidden");

    setTimeout(() => {
      cartModal.classList.add("hidden");
    }, 5250);
  }
}
